import { React, useContext, useEffect, useState } from 'react';
import { NVContext } from './NeuroVision'; // Путь к файлу, где создан контекст
import { Box, Skeleton } from '@mui/material';
import { CircularProgress } from '@mui/material';


const apiHost = process.env.REACT_APP_API_HOST;

export default function Photo() {
    const { state } = useContext(NVContext);
    const [loading, setLoading] = useState(false);  // Добавлено состояние загрузки
    const [imageUrl, setImageUrl] = useState('');   // Локальное состояние для URL картинки

    // Запрос информации
    const fetchImage = async (url) => {
        setLoading(true);
        try {
            const response = await fetch(`${apiHost}/image${url}`);
            if (response.ok) {
                const data = await response.blob(); // Преобразуем ответ в Blob (файл)
                const imageObjectUrl = URL.createObjectURL(data); // Преобразуем Blob в URL для отображения
                setImageUrl(imageObjectUrl);  // Сохраняем URL картинки
            } else {
                console.error('Error fetching files:', response.statusText);
            }
        } catch (error) {
            console.error("Error fetching files:", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (state?.images?.url) {  // Проверяем, существует ли объект и url
            fetchImage(state.images.url);
        }
    }, [state.images.url]);

    return (
        <Box width={'100%'} height={'100%'} position="relative">
            {imageUrl ? (
                <img src={imageUrl} width="100%" alt="Описание" style={{ borderRadius: 8 }} />
            ) : (
                <Box height={'100%'} bgcolor={'#aaa'} >Выберете снимок</Box>
            )}

            {loading && (
                <Box
                    position="absolute"
                    top="50%"
                    left="50%"
                    sx={{ transform: 'translate(-50%, -50%)', zIndex: 1 }} // Добавлен zIndex для перекрытия
                >
                    <CircularProgress />
                </Box>
            )}
        </Box>
    );
}


// loading ? (
// <CircularProgress/>
// ) :