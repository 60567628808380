// Урожайность
import { useState, useEffect, useContext } from 'react';
import { Paper, } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Field_Context } from '../Fields/Fields';

import * as React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';

import Loading from '../Wrappers/Loading';
const apiHost = process.env.REACT_APP_API_HOST;

// Основной модуль
function Сrop_yield() {

    return (
        <Paper elevation={2} sx={{ height: '100%', width: '100%' }}>
            <Loading apiHost={apiHost} url={'harvest'}>
                <СropTable />
            </Loading>
        </Paper>
    )

}



function СropTable({ data }) {
    // const data = 0
    const { state, setState, setActiveField } = useContext(Field_Context);

    const rows = [
        {
            "id": 311751,
            "field": "I-21",
            "crop": "Пар 2023",
            "year": 2023,
            "harvest": "0.00"
        },
    ];

    const setData = (newData) => {
        // console.log(newData);

        setState(prevState => ({
            ...prevState,
            data: newData
        }));
    };

    // Фильтрация данных по году номеру поля с мягкой фильтрацией
    const filteredRows = data.data.filter((row) => {
        // return (state === '' ? true : row.field.toString().includes(state.field)) // мягкая фильтрация
        return (state.field === '' || row.field.toString() === state.field)
            && (state.year === '' ? true : row.year.toString() === state.year)
            && (state.crop === '' ? true : row.crop.toString().includes(state.crop))
    });

    // const filteredRows = []

    useEffect(() => {
        setData(filteredRows);
    }, [state.year, state.field]); // Триггер при изменении state

    // Поиск
    const search = 0

    const paginationModel = { page: 0, pageSize: 25 };

    const [sortModel, setSortModel] = useState([
        {
            field: 'field',
            sort: 'asc', // Возможные значения: 'asc' или 'desc'
        },
    ]);

    const setFieldFilter = (newField) => {
        const field = newField.row.field
        setActiveField(field);
    }

    const columns = [
        // { field: 'id', headerName: 'ID', width: 70 },
        { field: 'field', headerName: 'Поле', width: 70 },
        { field: 'sowing', headerName: 'Посев', width: 100 },
        { field: 'crop', headerName: 'Культура', width: 150 },
        { field: 'year', headerName: 'Год', width: 70 },
        { field: 'harvest', headerName: 'Урожайность', width: 110 },
        { field: 'rating', headerName: 'Рейтинг', width: 70 },
    ];

    return (
        // Добавить фильтр лет
        <Paper elevation={2} sx={{ height: '50%', width: '100%' }}>
            <DataGrid
                rows={filteredRows}
                // rows={data.data}
                columns={columns}
                initialState={{ pagination: { paginationModel } }}
                pageSizeOptions={[5, 10, 25, 50, 100]}
                // checkboxSelection
                rowHeight={28}
                sx={{ border: 0 }}

                sortModel={sortModel}
                onSortModelChange={(model) => setSortModel(model)}

                onRowClick={setFieldFilter}
            />

            {filteredRows.length > 0 ? (<BasicLineChart props={filteredRows} />) : (<>График   </>)}
        </Paper>
    )
}

export default Сrop_yield


function BasicLineChart({ props }) {
    // Принимаем строки на вход

    const data = props.map(item => {
        return {
            x: item.year,
            y: item.harvest,
            label: item.harvest,
        };
    });


    const sortedData = data.sort((a, b) => a.x - b.x);

    return (
        <>
            <LineChart
                xAxis={[
                    {
                        data: sortedData.map(item => item.x), // массив годов
                        scaleType: 'band', // категориальная ось X 
                    },
                ]}
                series={[
                    {
                        curve: "linear",
                        data: sortedData.map(item => item.y),
                        scaleType: 'band', // категориальная ось X 
                        label: "Урожайность, ц/га",
                    },


                ]}
                width={800}
                height={400}
            />
        </>
    );
}

