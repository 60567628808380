// Корма
// import { Typography, Box, Skeleton } from '@mui/material';

import React, { useEffect, useState } from 'react';
import { Box, Grid, Card, CardContent, Typography } from '@mui/material';
// import { Gauge } from '@mui/x-charts/Gauge';

function Feed({ data }) {
    // const data = false
    return (
        // <Box width={200} height={200} >
        //     <Typography variant="subtitle1" gutterBottom>
        //         Полевые работы:
        //     </Typography>
        //     <Box sx={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
        //         {console.log(data)}


        //         {/* {data ? <> 123</> : <Skeleton variant="rectangular" width={50} height={50} />} */}
        //     </Box>
        // </Box>

        <Box sx={{ padding: 2 }}>
            <Grid container spacing={2}>
                {Object.keys(data).map((department) => (
                    <Grid item xs={12} key={department}>
                        <Typography variant="h5" gutterBottom>
                            Департамент: {department}
                        </Typography>

                        <Grid container spacing={2} >
                            {Object.keys(data[department]).map((sourceName) => (
                                <Grid item xs={12} sm={6} md={4} key={sourceName}>
                                    <Card variant="outlined">
                                        <CardContent >
                                            <Typography variant="h6">{sourceName}</Typography>
                                            {/* <Typography>За день: {data[department][sourceName].area.toFixed(2)} Га</Typography> */}
                                            {/* <Typography>Накопленная площадь: {data[department][sourceName].total.toFixed(2)} Га</Typography> */}
                                            {/* Процент от  */}
                                            {/* <Typography>Процент: {data[department][sourceName].percent_total.toFixed(2)} %</Typography> */}
                                            {/* <Gauge width={100} height={100} value={60} /> */}
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Box>

    );
}
export default Feed;

