// Сводка дня
import { Typography, Box, Skeleton } from '@mui/material';
import Feed from './Feed';
import Loading from '../Wrappers/Loading';

const apiHost = process.env.REACT_APP_API_HOST;

function Report() {
    "Сводка"
    const data = true
    // const data = false
    return (
        <>
            <Typography variant="h5" gutterBottom>
                Сводка данных
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
                <Loading apiHost={apiHost} url={'report'}>
                    <Feed />
                </Loading>
                {/* {data ? <Feed /> : <Skeleton variant="rectangular" width={300} height={200} />} */}
            </Box>
        </>
    );
}

export default Report;