import React from 'react';
import { Box, Typography, Skeleton } from '@mui/material';

function Settings() {
    return (
        <Box sx={{ p: 2, border: '1px dashed grey', width:'100%'}}>
            <Typography variant="h5" gutterBottom>
                Настройки
            </Typography>
            <Skeleton variant="rectangular" width="100%" height={400} />
        </Box>
    );
}

export default Settings;