// Главная для полей

import React, { useEffect, useState, createContext } from 'react';
import { Box, Button, Typography, IconButton } from '@mui/material';
import { List, ListItem, ListItemText, Skeleton } from '@mui/material';
import { Tabs, Tab } from '@mui/material';

import Map from '../Map/Map';
import Fertilizer from './Fertilizer'
import Field_rating from './field_rating'
import CropRotation from '../Crop_rotation/Crop_rotation'

import Select from '../Wrappers/Select'
import Сrop_yield from '../Сrop_yield/Сrop_yield'
import Stat_crop_yield from '../Сrop_yield/Statistics'
import List_fields from './List_fields'

import Test from '../Wrappers/Test'
import Mielta from './Mielta';
import Settings from './Settings';


import Loading from '../Wrappers/Loading';
const apiHost = process.env.REACT_APP_API_HOST;


// Создаем контекст с экспортом
export const Field_Context = createContext();

// function Service() {

//     const setService = (newData) => {
//         console.log("set:", newData);

//     };

//     return (
//         <Loading apiHost={apiHost} url={'test'}>
//             <Select
//                 onChange={setService}
//             />
//         </Loading>
//     )

// }


function Fields() {

    const [activeTab, setActiveTab] = useState(0);

    // Первичная настрока контекста
    const [state, setState] = useState({
        // Данные о полях
        data: [],
        year: localStorage.getItem('year') || '',
        crop: localStorage.getItem('crop') || '',
        field: localStorage.getItem('field') || '',
    });

    const setActiveField = (newField) => {
        console.log(newField);

        setState(prevState => ({
            ...prevState,
            activeField: newField
        }));
        localStorage.setItem('activeField', newField)
    };

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <Field_Context.Provider value={{ state, setState, setActiveField }}>
            <Box display="flex" height={"91vh"} >

                {/* 1-я колонка */}
                <Box display={'flex'} flex={1} flexDirection={'column'} gap={1} p={1} maxWidth={"300px"} sx={{ bgcolor: "#fff", borderRadius: 1, }}>
                    {/* <Service /> */}
                    {/* <Test /> */}
                    <Settings />
                    {/* <Loading apiHost={apiHost} url={'fields'}> */}
                    {/* Передаем данные в компоненты */}
                    {/* <List_fields /> */}
                    {/* </Loading> */}

                </Box>

                {/* 2-я колонка */}
                <Box flex={5} ml={1} overflow="auto" sx={{ background: "#fff", borderRadius: 1, p: 1 }}>
                    {/* Вкладки с службами */}
                    <Tabs value={activeTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto" sx={{ background: '#6666', borderRadius: 1, }}>
                        <Tab label="Урожайность" />
                        <Tab label="Рейтинг полей" disabled />
                        <Tab label="Хим. состав" />
                        <Tab label="Севооборот" disabled />

                        <Tab label="Карта" />
                        <Tab label="Датчик бункера" />
                        <Tab label="Карта осадков" />

                    </Tabs>

                    <Box overflow="auto" height={"84vh"}    >
                        {/* {activeTab === 0 && <Loading apiHost={apiHost} url={'harvest'}> <Сrop_yield /> /</Loading>} */}
                        {/* <Loading apiHost={apiHost} url={'harvest'}> <Test /> </Loading> */}

                        {activeTab === 0 && <Сrop_yield />}
                        {activeTab === 1 && <Field_rating />}
                        {activeTab === 2 && <Fertilizer />}
                        {activeTab === 3 && <CropRotation />}
                        {activeTab === 4 && <Map />}
                        {activeTab === 5 && <Mielta />}
                    </Box>

                </Box>

                {/* 3-я колонка */}
                <Box display={'flex'} flexDirection={'column'} gap={1} flex={2} ml={1} p={1} sx={{ bgcolor: "#fff", borderRadius: 1 }}>
                    <Box flex={3} >
                        {activeTab === 0 && <Stat_crop_yield />}

                    </Box>
                    <Box flex={2}  >
                        <Map />
                    </Box>
                </Box>
            </Box>
        </Field_Context.Provider>
    )
}

export default Fields