// Фильтры. Применяются к таблицам
import React, { useContext } from 'react';
import { Box, Button, Typography, TextField } from '@mui/material';

import { Field_Context } from "../Fields/Fields";

import AbcIcon from '@mui/icons-material/Abc';

function FieldsFilter() {
    "Блок настройками и фильтрами"
    const { state, setField } = useContext(Field_Context);

    return (
        <Box sx={{ bgcolor: "#6666", borderRadius: 1 }}>
            <Typography>Настройки для таблиц</Typography>
            <Fields_filter />
            <Year_filter />
            <Filter_crop />
        </Box>
    )
}

export default FieldsFilter

// Функции фильтрации
function Fields_filter() {
    "Фильтр для полей. Добавляет данные в контекст"

    const { state, setState } = useContext(Field_Context);

    const setField = (newField) => {
        console.log(newField);

        setState(prevState => ({
            ...prevState,
            field: newField
        }));
        localStorage.setItem('field', newField)
    };

    return (
        // <Box display="flex" alignItems="center" sx={{ position: 'relative', bgcolor: "#6666", borderRadius: 1 }}>
        <Box display="flex" sx={{ paddingLeft: 1, paddingRight: 1 }}>
            <TextField
                label="Поле"
                variant="outlined"
                size="small"
                value={state.field}
                onChange={(e) => setField(e.target.value)}
            />
            {/* Жесткая фильтрация */}
            <Button
                onClick={() => console.log("Слово целиком")}
                variant="outlined"
                size="small"
                sx={{ background: "#fff9" }}
            >
                <AbcIcon
                    // color="action"
                    color="success"
                    fontSize="small"

                />
            </Button>
        </Box>
    )
}

function Year_filter() {
    "Фильтр года"
    const { state, setState } = useContext(Field_Context);

    // Функции для контекста
    const setYearFilter = (newYaer) => {
        setState(prevState => ({
            ...prevState,
            year: newYaer
        }));
        localStorage.setItem('year', newYaer)
    };

    return (
        // <Box sx={{ bgcolor: "#6666", borderRadius: 1 }}>
        <TextField
            label="Год"
            variant="outlined"
            size="small"
            value={state.year}
            onChange={(e) => setYearFilter(e.target.value)} // Обновление фильтра
            sx={{ margin: 1 }}
        />
        // </Box>
    )
}


function Filter_crop() {
    "Фильтр культуры"
    const { state, setState } = useContext(Field_Context);

    const setCropFilter = (newCrop) => {
        setState(prevState => ({
            ...prevState,
            crop: newCrop
        }));
        localStorage.setItem('crop', newCrop)
    };

    return (
        // <Box sx={{ bgcolor: "#6666", borderRadius: 1 }}>
        <TextField
            label="Культура"
            variant="outlined"
            size="small"
            value={state.crop}
            onChange={(e) => setCropFilter(e.target.value)} // Обновление фильтра
            sx={{ margin: 1 }}
        />
        // </Box>
    )
}