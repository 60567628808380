import { React, useState, useEffect, useContext } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

// Иконки
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';

import { NVContext } from './NeuroVision'; // Путь к файлу, где создан контекст

const apiHost = process.env.REACT_APP_API_HOST;


const DataTable = () => {
    const [copied, setCopied] = useState(false);
    const { state, setImages } = useContext(NVContext);

    useEffect(() => {
        console.log("update", state.data);

    }, [state.data]
    )

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Название</TableCell>
                        <TableCell>Дата</TableCell>
                        <TableCell>Обнаруженные объекты</TableCell>
                        <TableCell>Координаты</TableCell>

                    </TableRow>
                </TableHead>

                <TableBody>
                    {state.data.map((row, index) => (
                        <TableRow key={index} onClick={() => setImages({ "url": row.url, "latlon": row.position })}>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.date}</TableCell>
                            <TableCell>
                                {row.detect.map((item, idx) => (
                                    <div key={idx}>
                                        {Object.entries(item).map(([key, value]) => (
                                            <div key={key}>{`ID: ${key}, Значение: ${value}`}</div>
                                        ))}
                                    </div>
                                ))}
                            </TableCell>

                            <TableCell>
                                {/* Функция копирования */}
                                <CopyCoordinatesButton coordinates={row.position} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default DataTable;


const CopyCoordinatesButton = ({ coordinates }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(coordinates)
            .then(() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 2000); // сброс через 2 секунды
            })
            .catch((err) => {
                console.error('Ошибка при копировании: ', err);
            });
    };

    return (
        <>
            <IconButton onClick={handleCopy} color={copied ? 'success' : 'primary'}>
                <ContentCopyIcon />
            </IconButton>
            {/* {copied && <span>Скопировано!</span>} */}
        </>
    );
};