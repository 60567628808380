import * as React from 'react';
import { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';

export default function Loading({ apiHost, url, children }) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null); // Сбрасываем ошибку при новом запросе
            try {
                // console.log(url);
                
                const response = await fetch(`${apiHost}/${url}`);
                // console.log(response);
                
                if (!response.ok) {
                    throw new Error(`Error fetching files: ${response.statusText}`);
                }
                const data = await response.json();
                setData(data);
            } catch (error) {
                setError(error.message);
                console.error("Error fetching files:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [apiHost, url]);

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <>
            {/* Передаем загруженные данные в дочерние компоненты */}
            {React.Children.map(children, child => {
                return React.cloneElement(child, { data });
            })}
        </>
    );
}
