// Подсчёт рулонов
import React, { useEffect, useState, createContext } from 'react';
import { Box, Button, Typography, IconButton } from '@mui/material';
import { List, ListItem, ListItemText, Skeleton } from '@mui/material';

import ImageIcon from '@mui/icons-material/Image';
import SettingsIcon from '@mui/icons-material/Settings';

import Data_Folders from './Data_folders'
import Table from './Table'
import Map from './Map'
import Photo from './Photo'

import Select from '../Wrappers/Select'
import Loading from '../Wrappers/Loading';
import Storage from '../Wrappers/Storage';
import FileExplorer from '../Wrappers/Explorer';

const apiHost = process.env.REACT_APP_API_HOST;


// Создаем контекст с экспортом
export const NVContext = createContext();


const data = [
    {
        "name": "DJI_0471.JPG",
        "url": "/mnt/s/2024/rolls/weed/DJI_0471.JPG",
        "date": "05.06.2023",
        "position": [
            50.175238,
            127.732258
        ],
        "detect": [
            {}
        ]
    },
    {
        "name": "DJI_0810.JPG",
        "url": "/mnt/s/2024/rolls/weed/DJI_0810.JPG",
        "date": "14.08.2024",
        "position": [
            50.128823,
            128.508075
        ],
        "detect": [
            {
                "0": 21
            }
        ]
    },
    {
        "name": "DJI_0811.JPG",
        "url": "/mnt/s/2024/rolls/weed/DJI_0811.JPG",
        "date": "14.08.2024",
        "position": [
            50.128749,
            128.50869
        ],
        "detect": [
            {
                "0": 14
            }
        ]
    },
    {
        "name": "DJI_0831.JPG",
        "url": "/mnt/s/2024/rolls/weed/DJI_0831.JPG",
        "date": "18.06.2024",
        "position": [
            50.138284,
            128.491065
        ],
        "detect": [
            {
                "0": 1
            }
        ]
    }
]

function NeuroVision() {

    // Первичная настрока контекста
    const [state, setState] = useState({
        // По компонентам
        data: data,
        model: null,
        path: null,
        images: {
            latlon: null,
            url: null
        },

    });

    // Функции для контекста
    const setData = (newData) => {
        setState(prevState => ({
            ...prevState,
            data: newData
        }));
    };

    const setModel = (newModel) => {
        setState(prevState => ({
            ...prevState,
            model: newModel
        }));
    };

    const setPath = (newPath) => {
        setState(prevState => ({
            ...prevState,
            path: newPath
        }));
    };

    const setImages = (newPath) => {
        setState(prevState => ({
            ...prevState,
            images: newPath
        }));
    };


    const [isDisabled, setIsDisabled] = useState(true);
    const [error, setError] = useState(null); // Добавил состояние для ошибок

    // Запрос информации
    const fetchFiles = async () => {
        try {
            console.log(`${apiHost}/run`);
            const response = await fetch(`${apiHost}/run`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "path": state.path,
                    "model": state.model
                })
            });

            if (response.ok) {
                const data = await response.json();
                setData(data)
                // console.log(data);

            } else {
                console.error('Error fetching files:', response.statusText);
                setError('Ошибка загрузки данных'); // Устанавливаем сообщение об ошибке

            }
        } catch (error) {
            console.error("Error fetching files:", error);
            setError('Ошибка сети или сервера'); // Сообщение об ошибке сети
        }
    }

    // fetchFiles();


    useEffect(() => {
        const check = async () => {
            // console.log(state.path, state.model);
            // console.log(state);

            if (state.path && state.model) { // Проверяем, что path и model заданы
                // console.log("ok", state.path, state.model);
                // Включаем кнопку
                setIsDisabled(false)
            }

            else {
                setIsDisabled(true)
                console.log('path or model no select');
            }
        }
        check();
    }, [state.path, state.model])

    const handleClick = () => {
        // const savedPath = localStorage.getItem('path');
        // const savedModel = localStorage.getItem('model');
        fetchFiles();
        // setPath(savedPath);
        // setModel(savedModel);
    }

    // const item.image = 'demo.lpg'

    return (
        <NVContext.Provider value={{ state, setState, setPath, setImages }}>
            <Box display="flex" height={"91vh"} >

                {/* 1-я колонка */}
                <Box display={'flex'} flexDirection={'column'} gap={1} p={1} maxWidth={"300px"} sx={{ bgcolor: "#fff", borderRadius: 1, }}>
                    <Typography variant="h5" gutterBottom>
                        Нейрозрение
                    </Typography>

                    <Loading apiHost={apiHost} url={'models'}>
                        <Select
                            onChange={setModel}
                        />
                    </Loading>

                    <Loading apiHost={apiHost} url={'folders'}>
                        <Data_Folders />
                    </Loading>

                    <Button
                        variant="contained"
                        onClick={handleClick}
                        disabled={isDisabled}
                    >
                        Запуск
                    </Button>

                    <Box bgcolor={'#999'}>
                        Список готовых:
                        {/* <Select onChange={ } /> */}
                    </Box>

                    {/* <IconButton bgcolor={'#999'}> */}
                    <Storage />
                    <SettingsIcon />
                    {/* Настройки: */}
                    {/* <Select onChange={ } /> */}
                    {/* </IconButton> */}
                    <FileExplorer/>

                    



                    {error && <Typography color="error">{error}</Typography>}
                    {/* {state.data && <div>{JSON.stringify(state.data)}</div>} */}
                </Box>

                {/* 2-я колонка */}
                <Box flex={3} ml={1} overflow="auto" sx={{ background: "#fff", borderRadius: 1, p: 1 }}>
                    <Box >
                        <Table />
                    </Box>

                    <Box >Итого:</Box>
                </Box>

                {/* 3-я колонка */}
                <Box display={'flex'} flexDirection={'column'} gap={1} flex={2} ml={1} p={1} sx={{ bgcolor: "#fff", borderRadius: 1 }}>
                    <Box flex={2} >
                        <Photo />
                    </Box>
                    <Box flex={3}  >
                        <Map />
                    </Box>
                </Box>
            </Box>
        </NVContext.Provider>
    );
}
export default NeuroVision