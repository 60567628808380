// Датчик бункера
import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

import Loading from '../Wrappers/Loading';
const apiHost = process.env.REACT_APP_API_HOST;

function Mielta({ data }) {

    return (
        <Loading apiHost={apiHost} url={'split_batches'}>
            <MieltaTable data={data} />
        </Loading>)
}

export default Mielta

function MieltaTable({ data }) {
    // console.log(data);

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>From</TableCell>
                        <TableCell>To</TableCell>
                        <TableCell>Percent</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    {data.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell>{new Date(item.from).toLocaleString()}</TableCell>
                            <TableCell>{new Date(item.to).toLocaleString()}</TableCell>
                            <TableCell>{item.percent.toFixed(2)}%</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer >
    );
}