// Севооборот. Версия 1 (V1). Простая система севооборота. Изначально выбирается схема. Далее посев согласно схеме.
// В версии 2 (V2) уже по индексам. На основе результатов исследований

function CropRotation() {
    "Севооборот"
    return (
        <>Севооборот по схемам. V1</>

    )
}

export default CropRotation