import { React, useState, useContext } from 'react';
import { Box, Typography } from '@mui/material/';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

import { NVContext } from './NeuroVision'; // Путь к файлу, где создан контекст

function renderTreeItems(node, handleItemClick) {
  // Если файлов нет, не отображаем их количество
  const count = node.files > 0 ? `${node.name} [${node.files}]` : `${node.name}`;

  return (
    <TreeItem
      itemId={node.url}
      key={node.url}
      label={count}
      onClick={() => handleItemClick(node.url)} // Добавляем обработчик клика
    >
      {Array.isArray(node.children)
        ? node.children.map((childNode) => renderTreeItems(childNode, handleItemClick))
        : null}
    </TreeItem>
  );
}

export default function BasicSimpleTreeView({ data, onClick }) {
  const [url, setUrl] = useState('');
  const { state, setPath } = useContext(NVContext);
  console.log(data);
  

  const handleItemClick = (url) => {
    // console.log(url);
    // Устанавливаем путь к папке с фото
    setPath(url)
  };

  return (
    <Box
      sx={{
        minWidth: 250,
        background: '#aaa',
        borderRadius: 1,
      }}
    >
      <Typography variant="subtitle1" component="div">Выбран путь: <br /> {url}</Typography>
      <SimpleTreeView sx={{ maxHeight: 300, background: '#fffc', overflowY: 'auto', padding: 1 }}>
        {renderTreeItems(data, handleItemClick)} {/* Передаем обработчик клика */}
      </SimpleTreeView>
    </Box>
  );
}