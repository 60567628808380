// Хранилища (S3, SFTP, ...)

import * as React from 'react';
import { Select, MenuItem, CircularProgress, FormControl, InputLabel } from '@mui/material';
import BasicSelect from './Select';
import { useFetch } from './useFetch'
import Loading from './Loading';

const apiHost = process.env.REACT_APP_API_HOST;

export default function Storage() {

    const { loading, data, error } = useFetch(apiHost, '/s3/buckets/');
    const [bucket, setBucket] = React.useState('');

    const handleChange = (event) => {
        setBucket(event.target.value);
    };

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <Loading apiHost={apiHost} url={'s3/buckets/'}>
            <FormControl variant="filled" sx={{ width: "100%" }}>
                <InputLabel>{"Хранилище"}</InputLabel>
                <Select
                    value={bucket}
                    // label={"Age"}
                    onChange={handleChange}
                >
                    {data.map((item) => (
                        <MenuItem key={item} value={item}>
                            {item}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Loading>
    );
}


// Добавить хранилище
// Диалог
// S3 [Логин пароль]
// Загрузка изображений (input)