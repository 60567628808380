import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

// Данные с индексами и ключевыми факторами
const keyFactors = [
  { id: 0, factor: 'Поле' },
  { id: 1, factor: 'Урожайность' },
  { id: 2, factor: 'Качество почвы' },
  { id: 3, factor: 'Обработка поля' }, // Вспашка, 
  { id: 4, factor: 'Погодные условия' },
  { id: 5, factor: 'Инвазивные растения и сорняки' },
  { id: 6, factor: 'Заболевания и вредители' },
  { id: 7, factor: 'История поля' },
  { id: 8, factor: 'Эффективность использования ресурсов' },
  { id: 9, factor: 'Текущие наблюдения' },
  { id: 10, factor: 'Экономические факторы' }
];

const fields = [
  { field: 'I-1', fert: 1, },
  { field: 'I-2', fert: 2, },
  { field: 'I-3', fert: 1, },
  { field: 'I-1', fert: 1, },
  { field: 'I-2', fert: 2, },
  { field: 'I-3', fert: 1, },
  { field: 'I-1', fert: 1, },
  { field: 'I-2', fert: 2, },
  { field: 'I-3', fert: 1, },
  { field: 'I-1', fert: 1, },
  { field: 'I-2', fert: 2, },
  { field: 'I-3', fert: 1, },
  { field: 'I-1', fert: 1, },
  { field: 'I-2', fert: 2, },
  { field: 'I-3', fert: 1, },
  { field: 'I-1', fert: 1, },
  { field: 'I-2', fert: 2, },
  { field: 'I-3', fert: 1, },
  { field: 'I-1', fert: 1, },
  { field: 'I-2', fert: 2, },
  { field: 'I-3', fert: 1, },
]

const Field_rating = () => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow >
            {keyFactors.map((row) => (
              <TableCell key={row.factor}>{row.factor}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {fields.map((row) => (
            <TableRow key={row.field}>
              <TableCell>{row.field}</TableCell>
              <TableCell>{row.fert}</TableCell>
              <TableCell>{row.fert}</TableCell>
              <TableCell>{row.fert}</TableCell>
              <TableCell>{row.fert}</TableCell>
              <TableCell>{row.fert}</TableCell>
              <TableCell>{row.fert}</TableCell>
              <TableCell>{row.fert}</TableCell>
              <TableCell>{row.fert}</TableCell>
              <TableCell>{row.fert}</TableCell>
              <TableCell>{row.fert}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Field_rating;
