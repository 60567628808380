import React, { useContext } from 'react';
import { Box, Button, Typography, IconButton } from '@mui/material';
import { Field_Context } from "../Fields/Fields";

function Statistics() {
    "Статистика для урожайности"
    const { state, setState } = useContext(Field_Context);

    return (
        <Box sx={{ bgcolor: "#6666", borderRadius: 1, height: '100%' }}>
            Статистика {state.activeField}
            <Box sx={{ bgcolor: "#6666", borderRadius: 1, }}>График урожайности по полю + средняя урожайность</Box>
            <Box sx={{ bgcolor: "#6666", borderRadius: 1, }}> Мин. Макс. </Box>
            <Box sx={{ bgcolor: "#6666", borderRadius: 1, }}>...</Box>
        </Box >
    )
}

export default Statistics