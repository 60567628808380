import * as React from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const data_example = {
    "id": "model",
    "label": "Модель",
    "list": [
        {
            "label": "roll",
            "value": "roll.pt"
        },
        {
            "label": "weed",
            "value": "weed.pt"
        }
    ]
}

export default function BasicSelect({ data, value, onChange }) {
    "Принимает данные и обработчик состояния"
    const [val, setVal] = React.useState(value || '');
    console.log(data);

    const handleChange = (event) => {
        const newValue = event.target.value;
        setVal(newValue);
        // Если есть onChange, передаем новое значение родительскому компоненту
        if (onChange) {
            onChange(newValue);
        }
    };

    return (
        <Box>
            <FormControl variant="filled" sx={{ width: "100%" }}>
                <InputLabel id={data.id}>{data?.label}</InputLabel>
                <Select
                    id={data.id}
                    value={val}
                    label={data?.label}
                    onChange={handleChange}
                >
                    {data.list.map((item, index) => (
                        <MenuItem key={index} value={item?.value}>
                            {item.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}
