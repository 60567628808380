import React, { useState, useEffect, useContext } from 'react';
import { MapContainer, TileLayer, useMap, LayersControl } from 'react-leaflet';
import L from 'leaflet';
// import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Box } from '@mui/material';
import 'leaflet/dist/leaflet.css';
// import { NVContext } from './NeuroVision'; // Путь к файлу, где создан контекст

// Создаем карту
const MapComponent = () => {
    const { BaseLayer, Overlay } = LayersControl;
    return (
        <MapContainer center={[50.21, 127.93]} zoom={13} maxZoom={16} style={{ height: '100%', width: '100%', borderRadius: 8 }}>
            <No_flag />
            <LayersControl position="topright">
                <BaseLayer checked name="OSM">
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='<a href="https://www.openstreetmap.org/copyright">OSM</a>'
                    />
                </BaseLayer>

                <Overlay name="БПЛА">
                    <TileLayer
                        url="https://ankmap.ru:8443/tiles/UAV/{z}/{x}/{y}.png"
                        attribution='<a href="https://ankmap.ru/">БПЛА</a>'
                    />
                </Overlay>

                <Overlay checked name="Поля">
                    <TileLayer
                        url="https://ardbot.nextgis.com/api/component/render/tile?resource=156&nd=204&z={z}&x={x}&y={y}"
                    />
                </Overlay>

                <Overlay name="Уборка">
                    <TileLayer
                        url="https://ankmap.ru:8443/tiles/clear/{z}/{x}/{y}.png"
                    />
                </Overlay>
        

                <Overlay name="Claas">
                    <TileLayer
                        url="https://ardbot.nextgis.com/api/component/render/tile?resource=123,120,118,116&nd=204&z={z}&x={x}&y={y}"
                    />
                </Overlay>

                <Overlay name="1 след">
                    <TileLayer
                        url="https://ankmap.ru:8443/tiles/1sled/{z}/{x}/{y}.png"
                    />
                </Overlay>
                <Overlay name="2 след">
                    <TileLayer

                        url="https://ankmap.ru:8443/tiles/2sled/{z}/{x}/{y}.png"
                    />
                </Overlay>

            </LayersControl>

            {/* Объект на карте */}
            {/* <LocationMarker /> */}

        </MapContainer>
    );
};




const locationIcon = new L.DivIcon({
    html: `<div style="color: blue; font-size: 24px;"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" width="24" height="24"><path d="M12 2C8.13401 2 5 5.13401 5 9C5 13.866 12 22 12 22C12 22 19 13.866 19 9C19 5.13401 15.866 2 12 2ZM12 11C10.8954 11 10 10.1046 10 9C10 7.89543 10.8954 7 12 7C13.1046 7 14 7.89543 14 9C14 10.1046 13.1046 11 12 11Z"/></svg></div>`,
    className: 'custom-marker-icon',
});


// Маркер
const LocationMarker = () => {
    const map = useMap();
    //   const { state } = useContext(NVContext);
    const { state } = '';

    // useEffect для обновления карты и зума
    useEffect(() => {
        if (state.images.latlon) {
            map.setView([50, 128], map.getZoom());
        }
    }, [map]);

    // useEffect для работы с маркером
    //   useEffect(() => {
    //     let marker;
    //     if (state.images.latlon) {
    //       marker = L.marker(state.images.latlon, { icon: locationIcon }).addTo(map);
    //     }

    //     return () => {
    //       if (marker) {
    //         map.removeLayer(marker); // Удаляем маркер при размонтировании компонента
    //       }
    //     };
    //   }, [state.images, map]);

    // Убираем флаг в атрибуции Leaflet
    useEffect(() => {
        map.attributionControl.setPrefix('Leaflet');
    }, [map]);

    return null; // Компонент ничего не рендерит напрямую
};

// Образец настроек
const Wrp = () => {

    return (
        <Box display="flex" height={"91vh"} flexDirection={'column'} sx={{ bgcolor: "#fff", borderRadius: 1 }}>
            <MapComponent />
        </Box>
    )
}

export default MapComponent;

const No_flag = () => {
    const map = useMap();
    // Убираем флаг в атрибуции Leaflet
    useEffect(() => {
        map.attributionControl.setPrefix('Leaflet');
    }, [map]);
}