import { useState, useEffect } from 'react';

export const useFetch = (apiHost, url) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null); // Сбрасываем ошибку при новом запросе
            try {
                const response = await fetch(`${apiHost}${url}`);
                if (!response.ok) {
                    throw new Error(`Error fetching data: ${response.statusText}`);
                }
                const data = await response.json();
                setData(data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [apiHost, url]);

    return { loading, data, error };
};
