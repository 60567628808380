// import React, { useRef } from 'react';

// const FileExplorer = () => {
//   const fileInputRef = useRef(null);

//   const handleButtonClick = () => {
//     fileInputRef.current.click(); // Открываем проводник
//   };

//   const handleFileChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       console.log('Выбранный файл:', file.name);
//     }
//   };

//   return (
//     <div>
//       {/* <button onClick={handleButtonClick}>Выбрать файл</button> */}
//       <input
//         type="file"
//         ref={fileInputRef}
//         // style={{ display: 'none' }} // Скрываем input
//         onChange={handleFileChange}
//       />
//     </div>
//   );
// };

// export default FileExplorer;


import React, { useRef } from 'react';

const FolderExplorer = () => {
  const folderInputRef = useRef(null);

  const handleButtonClick = () => {
    folderInputRef.current.click(); // Открываем окно выбора папки
  };

  const handleFolderChange = (event) => {
    const folder = event.target.files;
    if (folder.length) {
      console.log('Выбрана папка:', folder[0].webkitRelativePath);
    }
  };

  return (
    <div>
      <button onClick={handleButtonClick}>Выбрать папку</button>
      <input
        type="file"
        ref={folderInputRef}
        style={{ display: 'none' }}
        webkitdirectory="true" // Выбор папки
        onChange={handleFolderChange}
      />
    </div>
  );
};

export default FolderExplorer;
